import React, { useState } from "react";
import {
  Container,
  Content,
  Image,
  ImageSliderButton,
  StoryImageHover,
  PlayButton,
} from './image-slider.styled';
import { ZWrapper, Button, Icon } from "../../ui-components";
import GalleryFullScreen from "../gallery/gallery-fullscreen";


const ImageSlider = ({ data, title, width, height }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [fullScreenIndex, setFullScreenIndex] = useState(0);

  const enterFullScreen = () => {
    setFullScreenIndex(slideIndex);
    setFullScreen(true)
  }

  const handleNext = (event) => {
    event.preventDefault();
    const next = fullScreenIndex + 1 >= data.length ? 0 : fullScreenIndex + 1;
    setFullScreenIndex(next);
  }

  const handlePrev = (event) => {
    event.preventDefault();
    const prev = fullScreenIndex <= 0 ? data.length - 1 : fullScreenIndex - 1;
    setFullScreenIndex(prev);
  }

  const handleClose = (event) => {
    event.preventDefault();
    setFullScreenIndex(0);
    setFullScreen(false)
  }

  const slideNext = (event) => {
    event.preventDefault();
    const next = slideIndex + 1 >= data.length ? 0 : slideIndex + 1;
    setSlideIndex(next);
  }

  const slidePrev = (event) => {
    event.preventDefault();
    const prev = slideIndex <= 0 ? data.length - 1 : slideIndex - 1;
    setSlideIndex(prev);
  }
  return (
    <>
      {fullScreen
        ? (
          <GalleryFullScreen
            data={{
              type: data[fullScreenIndex].type,
              image: data[fullScreenIndex].fullscreen,
              youtube: data[fullScreenIndex].youtube,
            }}
            onNext={handleNext}
            onPrev={handlePrev}
            onClose={handleClose}
            disableNavigation={data.length === 1}
          />
        )
        : ''}
      <ZWrapper
        size="small"
        color="primary"
      >
        <Container
          width={width}
          height={height}
        >
          {data.length > 1
            ? <ImageSliderButton left>
              <Button
                color="thirdiary"
                size="icon"
                icon="caret-backward-sharp"
                nomargin
                onClick={slidePrev}
              />
            </ImageSliderButton>
            : null
          }
          {data.length > 1
            ? <ImageSliderButton right>
              <Button
                color="thirdiary"
                size="icon"
                icon="caret-forward-sharp"
                nomargin
                onClick={slideNext}
              />
            </ImageSliderButton>
            : null}
          <Content>
            <Image
              image={data[slideIndex].thumb}
              alt={title}
            />
            <StoryImageHover type={data[slideIndex].type || "image"} onClick={enterFullScreen}>
              <div>
                {data[slideIndex].type === "youtube"
                  ? <PlayButton onClick={enterFullScreen}>
                    <Icon
                      name="caret-forward-sharp"
                      size={32}
                      noFill
                    />
                  </PlayButton>
                  : <Button
                    color="thirdiary"
                    size="icon"
                    icon="search-outline"
                    nomargin
                    onClick={enterFullScreen}
                  />}
              </div>
            </StoryImageHover>
          </Content>
        </Container>
      </ZWrapper>
    </>
  )
}


export default ImageSlider;