import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


const angle = 3;

export const Container = styled.div`
  position: relative;
`;

export const Content = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
  /* overflow: scroll; */
`;


export const Image = styled(GatsbyImage)`
  flex: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-top: ${angle}px solid ${({ theme }) => theme.primary.main};
    border-left: ${angle}px solid transparent;
    border-bottom: ${angle}px solid ${({ theme }) => theme.primary.main};
    z-index: 100;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    border-top: ${angle}px solid ${({ theme }) => theme.primary.main};
    border-right: ${angle}px solid transparent;
    border-bottom: ${angle}px solid ${({ theme }) => theme.primary.main};
    z-index: 1;
  }
`;

export const ImageSliderButton = styled.div`
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -14px;
  
  ${props => props.left && css`
    left: -10px;
  `}

  ${props => props.right && css`
    right: -10px;
  `}
`


export const cutSize2 = 4;

export const StoryImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;  
  cursor: pointer;

  ${props => props.type === "image" && css`
    & > * {
      display: none;
    }

    &:hover{
      & > * {
        display: block;
      }
    }
  `}
`;

export const PlayButton = styled.button`
  border: 0;
  background: ${({ theme }) => theme.thirdiary.main}66;
  
  border-radius: 50%;
  height: 46px;
  width: 46px;
  
  padding-left: 10px;
  padding-top: 2px;
  fill: ${({ theme }) => theme.text.white};
  
  &:hover{
    background: ${({ theme }) => theme.thirdiary.dark}ff;
  }

  @media ${({ theme }) => theme.device.mobile}{
    width: 60px;
    height: 60px;
    padding-left: 10px;
  }
`