import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  text-align: center;
  /* padding: 42px 10px 42px; */
  /* background: ${({ theme }) => theme.background.paper}; */

  @media ${({ theme }) => theme.device.mobile}{
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 32px auto;
  text-align: left;
  display: block;

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.margin && css`
    margin: 32px auto;
  `}

  @media ${({ theme }) => theme.device.phone}{
    text-align: center;
  }
`;

export const Title = styled.h3`
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.text.primary};
  position: relative;
  display: block;

  @media ${({ theme }) => theme.device.phone}{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }

`;


export const Description = styled.div`
  text-align: left;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.text.secondary};
  font-size: 16px;
  line-height: 25px;
`;

export const Frame = styled.div`
  ${props => props.container && css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;

    @media ${({ theme }) => theme.device.mobile}{
      flex-direction: column;
      max-width: 460px;
      margin: auto;
    }

    @media ${({ theme }) => theme.device.phone}{
      flex-direction: column;
      max-width: 320px;
      margin: auto;
    }
    
    ${props => props.direction === "left" && css`
      flex-direction: row-reverse;
    `}
  `};

  ${props => props.image && css`
    flex-basis: 400px;
    padding: 8px;

    @media ${({ theme }) => theme.device.mobile}{
      flex-basis: auto;
    }
  `};

  ${props => props.text && css`
    flex: 1;
    padding: 8px 24px 8px 24px;

    ${props => props.direction === "right" && css`
      padding: 8px 0 8px 24px;
    `}
  `};
`
