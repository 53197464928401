import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  text-align: center;
  padding: 42px 10px 42px;
  background: ${({ theme }) => theme.background.paper};

  @media ${({ theme }) => theme.device.mobile}{
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 0 auto;
  text-align: left;
  display: block;

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.margin && css`
    margin: 32px auto;
  `}

  @media ${({ theme }) => theme.device.phone}{
    text-align: center;
  }
`;

export const Title = styled.h2`
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 32px;
  line-height: 35px;
  padding-bottom: 8px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text.primary};
  position: relative;
  display: inline-block;

  @media ${({ theme }) => theme.device.phone}{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }

  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 10%;
    right: 10%;
    bottom: -5px;
  }
`;


export const DescriptionMain = styled.p`
  text-align: center;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.text.secondary};
`;


