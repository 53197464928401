import React from "react";
import styled, { css } from "styled-components";
import {
  Button
} from '../../ui-components';


const ORDER_URL = 'https://my.betriton.com/preorder'


const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 72px 0;

  ${props => props.paper && css`
    background: ${({ theme }) => theme.background.paper};
  `}

  ${props => props.sticked && css`
    margin-bottom: -24px;
  `}

  @media ${({ theme }) => theme.device.phone}{
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Title = styled.h4`
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 32px;
  line-height: 35px;
  padding-bottom: 12px;
  color: ${({ theme }) => theme.text.primary};

  position: relative;
  display: inline-block;

  @media ${({ theme }) => theme.device.phone}{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }

  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 10%;
    right: 10%;
    bottom: -5px;
  }
`;

const Body = styled.p`
  max-width: 520px;
  margin: 24px auto;
`;


const PreOrder = (props) => (
  <Wrapper sticked={props.sticked} paper={props.paper}>
    <Title>Embrace the spirit of extraordinary</Title>
    <Body>
      Be a pioneer and get in the queue for a journey that will create a very special set of emotions and memories!
    </Body>
    <Button
      to={ORDER_URL}
      type="anchor"
      target="_self"
      color="secondary"
      size="large"
    >
      Pre-order
    </Button>
  </Wrapper>
)

export default PreOrder;
