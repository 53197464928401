import React from "react";
import {
  Wrapper,
  Title,
  Description,
  Container,
  Frame,
} from './use-case-builder.styled';
import ImageSlider from "../image-slider/image-slider";
import { ZWrapper } from "../../ui-components";
import { markdown2HTML } from '../../utils';


const UseCaseBuilder = ({ content }) => (
  <Wrapper>
    {content.map((data, index) => (
      <Container key={index}>
        <ZWrapper
          size="normal"
          color={index % 2 == 0 ? "background" : "paper"}
        >
          <Frame container direction={index % 2 == 0 ? "right" : "left"}>
            <Frame image>
              <ImageSlider
                title={data.title}
                data={data.images.map(data => ({ 
                  type: data.type,
                  youtube: data.youtube,
                  ...data.image.childImageSharp
                 }))}
                width={400}
                height={240}
              />
            </Frame>
            <Frame text direction={index % 2 == 0 ? "right" : "left"}>
              <Title dangerouslySetInnerHTML={{ __html: markdown2HTML(data.title) }} />
              <Description dangerouslySetInnerHTML={{ __html: markdown2HTML(data.description) }} />
              {/* {data.icon} */}
            </Frame>
          </Frame>
        </ZWrapper>
      </Container>
    ))}
  </Wrapper>
)

export default UseCaseBuilder;
