import React, { useState, useEffect } from "react";
import {
  GatsbyImage,
} from "gatsby-plugin-image";
import styled, { css } from "styled-components";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background.paper};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 10px auto;
  justify-content: center;
`;

const ContainerOverflow = styled.div`
  /* overflow: hidden; #TODO remove 
  overflow-x: scroll;
  height: 80px;
  width: 100vw; */
`;

const CarouselContent = styled.div`
  /* display: inline-flex;  #TODO remove 
  height: 80px;
  align-items: center;
  transition: all 750ms linear; */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  
  /* transition: all 250ms linear; */
  /* -ms-overflow-style: none; 
  scrollbar-width: none;   */

  /* &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    display: none;
  } */



  /* & > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  } */

/* 
  ${props => props.show === 4 && css`
    & > * {
      width: calc(100% / 4);
    }
  `} */

`;

const BlockCoverage = styled.a`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 80px;
  margin: 0;
  padding: 0;
`;

const ImageCoverage = styled(GatsbyImage)`
  /* filter: grayscale(100%);
  opacity: 0.5;
  &:hover, &:focus {
    filter: grayscale(0%);
    opacity: 1;
  } */
  margin: 0;
  padding: 0;
`;



const MediaLine = (props) => {
  const { list, show, infiniteLoop } = props;

  const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0)
  const [length, setLength] = useState(list.length)

  const [isRepeating, setIsRepeating] = useState(infiniteLoop && list.length > show)
  const [transitionEnabled, setTransitionEnabled] = useState(true)

  const [touchPosition, setTouchPosition] = useState(null)

  // Set the length to match current children from props
  useEffect(() => {
    setLength(list.length)
    setIsRepeating(infiniteLoop && list.length > show)
  }, [list, infiniteLoop, show])

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true)
      }
    }
  }, [currentIndex, isRepeating, show, length])

  const next = () => {
    if (isRepeating || currentIndex < (length - show)) {
      setCurrentIndex(prevState => prevState + 1)
    }
  }

  const prev = () => {
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1)
    }
  }

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e) => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      next()
    }

    if (diff < -5) {
      prev()
    }

    setTouchPosition(null)
  }

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const renderExtraPrev = () => {
    // let output = []
    // for (let index = 0; index < show; index++) {
    //   output.push(children[length - 1 - index])
    // }
    // output.reverse()
    // return output
  }

  const renderExtraNext = () => {
    // let output = []
    // for (let index = 0; index < show; index++) {
    //   output.push(children[index])
    // }
    // return output
  }


  return (
    <Wrapper>
      <Container>
        <ContainerOverflow
          className="carousel-content-wrapper"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <CarouselContent
            show={show}
          // style={{
          //   transform: `translateX(-${currentIndex * (100 / show)}%)`,
          //   transition: !transitionEnabled ? 'none' : undefined,
          // }}
          // onTransitionEnd={() => handleTransitionEnd()}
          >
            {/* {
              (length > show && isRepeating) &&
              renderExtraPrev()
            } */}

            {list.map((media, index) => (
              <BlockCoverage
                href={media.url}
                target="_blank"
                key={index}
              >
                <ImageCoverage
                  image={media.logo?.childImageSharp.gatsbyImageData}
                  alt={media.name}
                />
              </BlockCoverage>
            ))}

            <BlockCoverage
              href="/media-coverage"
              target="_self"
              more
            >
              + many more
            </BlockCoverage>
            {/* {
              (length > show && isRepeating) &&
              renderExtraNext()
            } */}
          </CarouselContent>
        </ContainerOverflow>
      </Container>
    </Wrapper>
  )
}

export default MediaLine;