import React from "react"
import { graphql } from "gatsby"
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
} from '../ui-components';
import { Hero } from "../components";
import MediaLine from "../components/media-line/media-line";
import MissionCallOn from "../components/mission-call-on/mission-call-on";
import UseCaseBuilder from "../components/use-case-builder/use-case-builder";
import FeatureTree from "../components/feature-tree/feature-tree";
import FeatureVersion from "../components/feature-versions/feature-versions";
import PreOrder from "../components/pre-order/pre-order";


const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const content = data.markdownRemark || {};
  const use_case = content.frontmatter.use_case || [];
  const features = content.frontmatter.features || [];
  const products = content.frontmatter.products || [];

  const mediaCoverage = data.mediaCoverage.nodes.map(element => element.childMarkdownRemark.frontmatter) || [];

  return (
    <>
      <SEO
        canonical="/"
        title={siteTitle}
      />
      <Layout id="layout">
        <LayoutHeader>
          <Header home />
        </LayoutHeader>
        <LayoutMain>
          <Hero
            title={content.frontmatter.hero_title}
            description={content.frontmatter.hero_description}
            video={content.heroVideo}
          />
          <MediaLine
            show={4}
            infiniteLoop={true}
            list={mediaCoverage}
          />
          <MissionCallOn
            title={content.frontmatter.callon_title}
            description={content.frontmatter.callon_description}
          />
          <FeatureVersion
            products={products}
          />
          <FeatureTree
            title={content.frontmatter.feature_title}
            description={content.frontmatter.feature_description}
            features={features}
          />
          <UseCaseBuilder
            content={use_case}
          />
          <PreOrder
            sticked
            paper
          />
        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}


export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/content/pages/main.md/"}) {
      id
      frontmatter {
        hero_title
        hero_description
        callon_title
        callon_description
        use_case {
          title
          icon
          description
          images{ 
            type
            image {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 400
                  height: 245
                  placeholder: BLURRED
                  formats: [WEBP]
                )
                fullscreen: gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
            youtube
          }
        }
        feature_title
        feature_description
        features {
          title
          description
          image {
            childImageSharp {
              thumb: gatsbyImageData(
                height: 100
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        products {
          title
          description
          image {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 480
                height: 320
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
          url
          url_type
          url_name
          url_color
        }
        gallery_title
      }
      html
    }
    mediaCoverage: allFile(filter: {absolutePath: {regex: "//content/media_coverage//"}, childrenMarkdownRemark: {elemMatch: {frontmatter: {in_home: {eq: true}}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            url
            name
            quote
            logo{
              childImageSharp {
                gatsbyImageData(
                  width: 110
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
