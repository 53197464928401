import React from "react";
import {
  Wrapper,
  Title,
  Description,
  Container,
  FrameAnchor,
  Frame,
  FeatureTitle,
  // FeatureDescription,
  FeatureImage,
  ProductTitle,
  // ProductDescription,
  ProductImage,
  FeatureLogo,
  FeatureVersions,
} from './feature-tree.styled';
import { Button, ZWrapper } from "../../ui-components";
import LogoSimpleImageSrc from '../../assets/img/logo-simple.png';
import { markdown2HTML } from '../../utils';



const getFeatureSide = (index) => {
  switch (index) {
    default:
    case 0:
      return "left";
    case 1:
      return "middle";
    case 2:
      return "right";
  }
}

const FeatureTree = ({ title, description, features }) => (
  <Wrapper>
    <Container center>
      <Title dangerouslySetInnerHTML={{ __html: markdown2HTML(title) }} />
      <Description>{description}</Description>
      <Frame container_pictogram>
        {features.map((data, index) => (
          <Frame
            key={index}
            position={getFeatureSide(index)}
            row
          >
            <ZWrapper
              size="small"
              color="primary"
            >
              <Frame frame>
                <Frame image>
                  <FeatureImage
                    image={data.image.childImageSharp.thumb}
                    alt={data.title}
                  />
                </Frame>
              </Frame>
              <FeatureTitle>{data.title}</FeatureTitle>
            </ZWrapper>
          </Frame>
        ))}
      </Frame>
    </Container>
  </Wrapper>
)

export default FeatureTree;
