import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  text-align: center;
  padding: 24px 10px 24px;
  background: ${({ theme }) => theme.background.paper};

  @media ${({ theme }) => theme.device.mobile}{
    padding-left: 10px;
    padding-right: 10px;
  }

  ${props => props.color === "background" && css`
    padding: 10px 10px 24px;
    background: ${({ theme }) => theme.background.default};
  `}

`;

export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 32px auto;
  text-align: left;
  display: block;

  ${props => props.center && css`
    text-align: center;
  `}


  @media ${({ theme }) => theme.device.phone}{
    text-align: center;
  }
`;

export const Title = styled.h2`
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 32px;
  line-height: 35px;
  padding-bottom: 8px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text.primary};
  position: relative;
  display: inline-block;

  @media ${({ theme }) => theme.device.phone}{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }

  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 10%;
    right: 10%;
    bottom: -5px;
  }
`;


export const Description = styled.div`
  text-align: center;
  margin-bottom: 36px;
  color: ${({ theme }) => theme.text.secondary};
  font-size: 16px;
  line-height: 25px;
`;

export const FrameAnchor = styled.a``

export const Frame = styled.div`
  ${props => props.container && css`
    display: flex;
    flex-direction: row;

    @media ${({ theme }) => theme.device.mobile}{
      flex-direction: column;
      max-width: 320px;
      margin: auto;
    }
  `};

  ${props => props.container_pictogram && css`
    display: flex;
    flex-direction: row;
  
    @media ${({ theme }) => theme.device.phone}{
      /* flex-direction: column; */
      /* min-width: 200px;
      margin: auto; */
    }
  `};

  ${props => props.row && css`
    padding: 0 4px;
    flex: 1;
    @media ${({ theme }) => theme.device.tablet}{
      margin-bottom: 20px;
    }
    @media ${({ theme }) => theme.device.mobile}{
      margin-bottom: 20px;

    }
  `};


  ${props => props.frame && css`
    min-height: 110px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 0 20px;
  `};

  ${props => props.image && css`
    margin: 10px 0 0 0;
  `};

  ${props => props.center && css`
    text-align: center;
    margin: 20px auto;
  `};

  ${props => props.productrow && css`
    padding: 0 8px;
    flex: 1;
    display: flex;

    @media ${({ theme }) => theme.device.mobile}{
      display: block;
    }

    ${props => props.position === "left" && css`
      text-align: right;
      justify-content: end;

      @media ${({ theme }) => theme.device.mobile}{
        text-align: center;
      }
    `}

    ${props => props.position === "right" && css`
      text-align: left;
      @media ${({ theme }) => theme.device.mobile}{
        text-align: center;
      }
    `}

    ${props => props.position === "center" && css`
      text-align: center;
    `}
  `};

  ${props => props.productcol && css`
    @media ${({ theme }) => theme.device.mobile}{
      margin-bottom: 24px;
    }
    /* max-width: 320px; */
  `}
`;

export const FeatureTitle = styled.h3`
  color: ${({ theme }) => theme.text.white};
  margin: 10px 0;
  padding: 8px;

  font-family: "Quantico", sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 21px;
  line-height: 27px;


  @media ${({ theme }) => theme.device.phone}{
    font-size: 14px;
  }
`;

export const FeatureDescription = styled.div`
  color: ${({ theme }) => theme.text.white};
  margin: 0 0 10px 0;
  padding: 0 8px 8px;
  font-size: 16px;
  line-height: 21px;
`;

export const FeatureImage = styled(GatsbyImage)`
  max-width: 120px;
  /* max-height: 120px; */

  @media ${({ theme }) => theme.device.phone}{
    /* height: 80px; */
  }
`;

export const ProductTitle = styled.h3`
  color: ${({ theme }) => theme.text.primary};
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  margin: 8px;
  margin-bottom: 4px;
  font-size: 18px;
`;

export const ProductDescription = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

export const ProductImage = styled(GatsbyImage)`

`;

export const FeatureLogo = styled.img`
  max-width: 80px;
`;

export const FeatureVersionTitle = styled.h4`
  color: ${({ theme }) => theme.text.primary};
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin: 8px 0;

`
