import React from "react";
import {
  Wrapper,
  Container,
  FrameAnchor,
  Frame,
  ProductTitle,
  ProductImage,
  FeatureLogo,
  FeatureVersionTitle,
} from './feature-versions.styled';
import { Button, ZWrapper } from "../../ui-components";
import LogoSimpleImageSrc from '../../assets/img/logo-simple.png';
import { markdown2HTML } from '../../utils';

const FeatureVersion = ({ products }) => (
  <Wrapper color="background">
    <Container center>
      <Frame container>
        <Frame center>
          <FeatureLogo src={LogoSimpleImageSrc} alt="BeTRITON Versions" />
          <FeatureVersionTitle><strong>BeTRITON</strong> VERSIONS</FeatureVersionTitle>
        </Frame>
      </Frame>

      <Frame container>
        {products.map((data, index) => (
          <Frame
            key={index}
            position="center"
            productrow
          >
            <Frame productcol>
              <FrameAnchor
                href={data.url}
              >
                <ZWrapper
                  size="small"
                  color="primary"
                >
                  <ProductImage
                    image={data.image.childImageSharp.thumb}
                    alt={data.title}
                  />
                </ZWrapper>

              </FrameAnchor>
              <ProductTitle dangerouslySetInnerHTML={{ __html: markdown2HTML(data.title) }} />
              {data.url && (
                <Button
                  type={data.url_type}
                  to={data.url}
                  target="_self"
                  color={data.url_color}
                  size="normal"
                  onClick={(e) => { }}
                >
                  {data.url_name}
                </Button>
              )}
            </Frame>
          </Frame>
        ))}
      </Frame>
    </Container>
  </Wrapper>
)

export default FeatureVersion;
